import WProofreaderSDK    from '@webspellchecker/wproofreader-sdk-js';
import { checkFeatureFlag }  from '~/helpers/FeatureFlagChecker';

export const initWebSpellChecker = (element) => {
  const isSpellCheckEnabled = checkFeatureFlag(process.env.ALLOW_SPELLING_GRAMMAR_CHECK_FLAG);
  if (!isSpellCheckEnabled) return;

  const { WSC_SERVICE_ID } = process.env;
  if (WSC_SERVICE_ID) {
    WProofreaderSDK.init({
      serviceId:       WSC_SERVICE_ID,
      autoSearch:      true,
      autoDestroy:     true,
      autocorrect:     true,
      autocomplete:    true,
      enforceAI:       true,
      serviceProtocol: 'https',
      servicePort:     '443',
      serviceHost:     'svc.webspellchecker.net',
      servicePath:     'api',
      container:       element,
      lang:            'en_US',
    });
  }
};
