const config = {
  API: {
    GraphQL: {
      endpoint:        process.env.APPSYNC_ENDPOINT,
      region:          process.env.APPSYNC_REGION,
      defaultAuthMode: 'apiKey',
      apiKey:          process.env.APPSYNC_API_KEY,
    },
  },
};

export default config;
