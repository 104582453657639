import { useReducer, useRef } from 'react';
import { CognitoIdentityClient } from '@aws-sdk/client-cognito-identity';
import { fromCognitoIdentityPool } from '@aws-sdk/credential-providers';
import { SignatureV4 } from '@aws-sdk/signature-v4';
import { Sha256 } from '@aws-crypto/sha256-browser';
import { modalReducer, initialModalState } from './useAIAssist.reducer';
import { ModalStates } from '../states/ModalStates';
import APIRequest from '~/lib/api_request';

const CONFIG = {
  region:         process.env.AWS_REGION || 'us-east-1',
  lambdaFunction: process.env.AWS_LAMBDA_OPEN_AI_FUNCTION,
  identityPoolId: process.env.COGNITO_IDENTITY_POOL_ID,
};
const CREATE_CONVERSATION_API = '/v1/openai/create_conversation';
const GET_TOKENS_USAGE = '/v1/openai/tokens_usage';

const createCredentialsProvider = () => fromCognitoIdentityPool({
  client:         new CognitoIdentityClient({ region: CONFIG.region }),
  identityPoolId: CONFIG.identityPoolId,
});

const useAIAssist = (editor, mode, setSubject) => {
  const [state, dispatch] = useReducer(modalReducer, initialModalState);
  const stateRef = useRef(state);
  const appModalRef = useRef();
  const markdownContentRef = useRef();
  const credentialsProvider = useRef(createCredentialsProvider());

  const createSignedRequest = async (payload) => {
    try {
      if (!CONFIG.lambdaFunction) {
        throw new Error('Lambda function URL not configured');
      }

      const url = new URL(CONFIG.lambdaFunction);
      const request = {
        method:   'POST',
        protocol: url.protocol,
        hostname: url.hostname,
        path:     url.pathname,
        headers:  {
          'Content-Type': 'application/json',
          host:           url.hostname,
        },
        body: JSON.stringify(payload),
      };

      const signer = new SignatureV4({
        credentials: credentialsProvider.current,
        region:      CONFIG.region,
        service:     'lambda',
        sha256:      Sha256,
      });

      return await signer.sign(request);
    } catch (error) {
      console.error('Failed to create signed request:', error);
      throw error;
    }
  };

  const sendToAI = async (payload) => {
    const controller = new AbortController();

    try {
      const signedRequest = await createSignedRequest(payload);

      const response = await fetch(CONFIG.lambdaFunction, {
        method:  signedRequest.method,
        headers: signedRequest.headers,
        body:    signedRequest.body,
        signal:  controller.signal,
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const reader = response.body?.getReader();

      await handleResponseStream(reader);
    } catch (error) {
      console.error('AI Assistant Error:', error);
      dispatch({ type: 'SET_ERROR', payload: error.message });
    }
  };

  const handleInputChange = (e) => dispatch({ type: 'SET_INPUT_TEXT', payload: e.target.value });
  const handlePromptClick = (prompt) => dispatch({ type: 'SET_INPUT_TEXT', payload: prompt });
  const handleNewPrompt = () => dispatch({ type: 'RESET_STATE' });
  const handleRevisePrompt = () => dispatch({ type: 'REVISE_PROMPT' });

  const isJSON = (str) => {
    try {
      JSON.parse(str);
      return true;
    } catch (e) {
      return false;
    }
  };

  const processError = (outputText) => {
    if (isJSON(outputText)) {
      const responseJson = JSON.parse(outputText);

      if (responseJson.message) {
        dispatch({ type: 'SET_STATUS', payload: ModalStates.ERROR });
        dispatch({ type: 'SET_ERROR', payload: responseJson.message });

        return true;
      }
    }

    return false;
  };

  const startConversation = async () => {
    try {
      const response = await APIRequest.post({
        resource: CREATE_CONVERSATION_API,
      });

      return response.body.id;
    } catch (error) {
      dispatch({
        type:    'SET_ERROR',
        payload: error.message,
      });

      return null;
    }
  };

  const saveContext = async (data) => {
    await APIRequest.post({
      resource: '/v1/openai/create_message',
      data:     {
        ...data,
        ai_conversation_id: stateRef.current.conversationId,
        tokens_count:       stateRef.current.tokensCount,
      },
    });
  };

  const fetchTokensUsage = async () => {
    dispatch({ type: 'SET_LOADING', payload: true });

    try {
      const response = await APIRequest.get({ resource: GET_TOKENS_USAGE });
      const { subscription_limits, tokens_count } = response.body;

      dispatch({ type: 'SET_SUBSCRIPTION_LIMIT', payload: subscription_limits });
      dispatch({ type: 'SET_TOTAL_TOKENS_USED', payload: tokens_count });

      return null;
    } catch (error) {
      dispatch({
        type:    'SET_ERROR',
        payload: error.message,
      });

      return null;
    } finally {
      dispatch({ type: 'SET_LOADING', payload: false });
    }
  };

  const handleConversationId = async (conversationId) => {
    const id = conversationId || (await startConversation());

    if (!id) return false;

    dispatch({ type: 'SET_CONVERSATION_ID', payload: id });

    return true;
  };

  const validateInput = (inputText, totalTokens) => {
    if (!inputText.trim()) {
      return 'Prompt cannot be empty.';
    }

    if (totalTokens > process.env.MAX_TOKENS_PER_CONVERSATION) {
      return `Input exceeds the maximum allowed tokens (${process.env.MAX_TOKENS_PER_CONVERSATION}). Total tokens including current conversation: ${totalTokens}.`;
    }

    return null;
  };

  const extractSubject = (content, subjectPrefix) => {
    if (!content.includes(subjectPrefix)) return { content, subject: null };

    const startIndex = content.indexOf(subjectPrefix);
    const endIndex = content.indexOf('</div>', startIndex);
    let subject = content.slice(startIndex + subjectPrefix.length, endIndex === -1 ? undefined : endIndex);

    subject = subject.replace(/<\/?[^>]+(>|$)/g, '');

    const endIndexToReplace = content.indexOf('</div>', endIndex + '</div>'.length);
    content = content.replace(content.slice(startIndex, endIndexToReplace + '</div>'.length), '');

    return { content, subject };
  };

  const hideModal = (modalRef) => {
    const $modal = $(modalRef.modal);
    $modal.modal('hide');
  };

  const handleInsert = () => {
    if (state.status === ModalStates.GENERATING) return;

    const htmlContent = markdownContentRef.current.innerHTML;
    const subjectPrefix = 'Subject: ';
    const modalRef = appModalRef.current;
    const { content, subject } = extractSubject(htmlContent, subjectPrefix);

    editor.setContent(content, { format: mode === 'html' ? 'html' : 'text' });

    handleSubjectChange(subject);

    hideModal(modalRef);
  };

  const handleSubjectChange = (subject) => {
    if (subject && setSubject) setSubject(subject);
  };

  const handleResponseStream = async (reader) => {
    let outputText = '';

    const readStream = () => {
      reader.read().then(processStream).catch((err) => {
        dispatch({ type: 'SET_STATUS', payload: ModalStates.ERROR });
        dispatch({ type: 'SET_ERROR', payload: 'An error occurred while reading the stream.' });
      });
    };

    const processStream = ({ done, value }) => {
      const chunk = new TextDecoder().decode(value);
      outputText += chunk;

      if (done) {
        if (!processError(outputText)) {
          const newMessage = { role: 'assistant', content: outputText };
          dispatch({ type: 'SET_STATUS', payload: ModalStates.FINISHED });
          dispatch({ type: 'APPEND_MESSAGE', payload: newMessage });

          saveContext(newMessage);
        }

        return;
      }

      dispatch({ type: 'SET_OUTPUT_TEXT', payload: outputText });

      readStream();
    };

    readStream();
  };

  const handleGenerateText = async (overrideMessage) => {
    const inputText = overrideMessage || state.inputText;
    const validationError = validateInput(inputText, stateRef.current.tokensCount);

    if (validationError) {
      dispatch({
        type:    'SET_INPUT_ERROR',
        payload: validationError,
      });

      return;
    }

    if (!await handleConversationId(state.conversationId)) return;

    dispatch({ type: 'SET_STATUS', payload: ModalStates.GENERATING });

    const newMessage = { role: 'user', content: inputText };

    dispatch({ type: 'APPEND_MESSAGE', payload: newMessage });

    await saveContext(newMessage);

    const payload = { messages: [...stateRef.current.messages, newMessage] };

    await sendToAI(payload);
  };

  const handleRetry = async () => {
    dispatch({ type: 'REVISE_PROMPT' });

    const validationError = validateInput(state.inputText, stateRef.current.tokensCount);

    if (validationError) {
      dispatch({
        type:    'SET_INPUT_ERROR',
        payload: validationError,
      });

      return;
    }

    if (!await handleConversationId(state.conversationId)) return;

    dispatch({ type: 'SET_STATUS', payload: ModalStates.GENERATING });

    const payload = { messages: [...stateRef.current.messages] };

    await sendToAI(payload);
  };

  const handleRegenerate = async () => {
    const regenerateInstruction = 'Please regenerate the last response.';

    dispatch({ type: 'SET_OUTPUT_TEXT', payload: '' });
    dispatch({ type: 'SET_FEEDBACK_STATE', payload: null });
    dispatch({ type: 'SET_FEEDBACK_TEXT', payload: '' });

    handleGenerateText(regenerateInstruction);
  };

  return {
    state,
    dispatch,
    appModalRef,
    stateRef,
    markdownContentRef,
    handleInputChange,
    handlePromptClick,
    handleNewPrompt,
    handleRevisePrompt,
    startConversation,
    handleInsert,
    handleGenerateText,
    handleRegenerate,
    handleRetry,
    fetchTokensUsage,
  };
};

export default useAIAssist;
