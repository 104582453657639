import React, {
  useState, useEffect, useCallback, useRef,
} from 'react';
import PropTypes                                           from 'prop-types';
import { FontAwesomeIcon }                                 from '@fortawesome/react-fontawesome';
import ReactPaginate                                       from 'react-paginate';
import { Input }                                           from 'reactstrap';
import debounce                                            from 'lodash/debounce';
import UserAvatar                                          from '~/components/shared/users/user_avatar';
import DocumentActions                                     from '~/actions/document_actions';
import DocumentStore                                       from '~/stores/document_store';
import AppModal                                            from './app_modal';
import { formatedFileSize, formatedDate }                  from '~/helpers/document_helpers.jsx';
import IconInput                                           from '~/components/forms/IconInput';
import { TeammatePicker }                                  from '~/components/forms/team_member_picker';

const SelectDocumentModal = ({
  containerID, modalClass, dialogClass, onInsertClick, handleClose,
}) => {
  const [loadingDocuments, setLoadingDocuments] = useState(true);
  const [documents, setDocuments] = useState([]);
  const [selectedDocuments, setSelectedDocuments] = useState([]);
  const [selectAll, setSelectAll] = useState(false);
  const { currentUser } = Rails.helpers;
  const [filters, setFilters] = useState({
    query:    '',
    owner_id: [currentUser.id],
  });
  const [pagination, setPagination] = useState({
    total_pages: 1, current_page: 1, total_count: 0, per_page: 10,
  });

  const appModalRef = useRef(null);

  useEffect(() => {
    const documentStoreListener = DocumentStore.addListener(() => {
      const documentState = DocumentStore.getState();
      const { scopedRecords, lastDocumentStoreAction, pagination } = documentState;
      const { documents: documentsScopedRecords } = scopedRecords;

      if (lastDocumentStoreAction === 'loadDocumentsDone') {
        setDocuments(documentsScopedRecords.documents);
        setPagination(pagination);
        setLoadingDocuments(false);
      }
    });

    return () => {
      documentStoreListener.remove();
    };
  }, []);

  useEffect(() => {
    debounce(loadDocuments, 500)(1);
  }, [filters]);

  const loadDocuments = (page = pagination.current_page, size = pagination.per_page) => {
    setLoadingDocuments(true);
    DocumentActions.loadDocuments(undefined, { page, size, searchData: filters });
  };

  const onPageChange = (data) => {
    const newPage = data.selected + 1;
    loadDocuments(newPage);
  };

  const handleSelectDocument = (documentId) => {
    setSelectedDocuments((prevSelected) => (prevSelected.includes(documentId)
      ? prevSelected.filter((id) => id !== documentId)
      : [...prevSelected, documentId]));
  };

  const handleSelectAll = () => {
    if (selectAll) {
      setSelectedDocuments([]);
    } else {
      setSelectedDocuments(documents.map((document) => document.id));
    }
    setSelectAll(!selectAll);
  };

  const handleInsertClick = () => {
    const selectedDocs = documents.filter((document) => selectedDocuments.includes(document.id));
    onInsertClick(selectedDocs);
  };

  const handlePerPageChange = (e) => {
    const per_page = e.target.value;
    setPagination({ ...pagination, per_page });
    loadDocuments(1, per_page);
  };

  const titleStyle = {
    maxWidth:     '500px',
    overflow:     'hidden',
    textOverflow: 'ellipsis',
  };

  const renderDocuments = () => {
    if (documents.length === 0) {
      return <div className="text-center">There are no documents.</div>;
    }

    return (
      <div className="table-responsive width-calc ">
        <table className="table table-hover no-border">
          <tbody>
            <tr className="text-secondary">
              <th>
                <input
                  type="checkbox"
                  checked={selectAll}
                  onChange={handleSelectAll}
                />
              </th>
              <th>NAME</th>
              <th>CREATED</th>
              <th>SIZE</th>
              <th>CATEGORY</th>
              <th>OWNER</th>
            </tr>
            {documents.map((document) => renderDocumentRow(document))}
          </tbody>
        </table>
      </div>
    );
  };

  const renderDocumentRow = (document) => {
    const {
      title, size, uploaded_at, category,
    } = document;

    return (
      <tr key={document.id} onClick={() => handleSelectDocument(document.id)}>
        <td>
          <input
            type="checkbox"
            checked={selectedDocuments.includes(document.id)}
          />
        </td>
        <td style={titleStyle}>{title}</td>
        <td>{formatedDate(uploaded_at)}</td>
        <td>{formatedFileSize(size)}</td>
        <td>{category?.name}</td>
        <td>
          {
            document.owner ? (
              <UserAvatar tooltip user={document.owner} />
            ) : <span className="ml-2">-</span>
          }
        </td>
      </tr>
    );
  };

  const renderPagination = () => (
    <div className="mt-2">
      <div className="pull-left ml-3">
        <Input
          type="select"
          name="selectPerPage"
          defaultValue={pagination.per_page}
          onChange={handlePerPageChange}
          style={selectStyle}
        >
          <option value="10">10</option>
          <option value="25">25</option>
          <option value="50">50</option>
          <option value="100">100</option>
        </Input>
        <span className="ml-3">items per page</span>
      </div>

      {pagination.total_pages > 1 && (
        <div className="pull-right">
          <nav>
            <ReactPaginate
              forcePage={pagination.current_page - 1}
              pageCount={pagination.total_pages}
              pageRangeDisplayed={1}
              marginPagesDisplayed={2}
              containerClassName="pagination"
              activeClassName="active"
              breakLabel="..."
              breakLinkClassName="page-link disabled"
              breakClassName="page-item"
              pageClassName="page-item"
              pageLinkClassName="page-link"
              previousClassName="page-item"
              previousLinkClassName="page-link"
              nextClassName="page-item"
              nextLinkClassName="page-link"
              disabledClassName="disabled"
              onPageChange={onPageChange}
              previousLabel={Rails.isMobile ? '<' : 'Previous'}
              nextLabel={Rails.isMobile ? '>' : 'Next'}
            />
          </nav>
        </div>
      )}
    </div>
  );

  const selectStyle = { display: 'inline-block', width: 'auto', verticalAlign: 'middle' };

  return (
    <AppModal
      containerID={containerID}
      modalClass={modalClass}
      dialogClass={dialogClass}
      ref={appModalRef}
    >
      <div className="modal-header">
        <h5 className="modal-title">Insert Document</h5>
        <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={handleClose}>
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div className="modal-body">
        <div className="row">
          <div className="col-3 ml-4 pr-0">
            <IconInput
              iconPrefix="far"
              icon="search"
              side="left"
              type="text"
              className="form-control"
              placeholder="Search..."
              value={filters.query}
              onChange={(e) => setFilters({ ...filters, query: e.target.value })}
              onKeyDown={(e) => e.key === 'Enter' && setFilters({ ...filters, query: e.target.value })}
              disabled={loadingDocuments}
              name="search"
            />
          </div>
          <div className="col-3  pr-0">
            <TeammatePicker
              id="search_owner"
              value={filters.owner_id}
              placeholder="---Filter by User---"
              onChange={(opt) => {
                setFilters({ ...filters, owner_id: opt.map((a) => a.value) || [] });
              }}
              clearable
              multi
              skipAvatar
              disabled={loadingDocuments}
              apiResource="/v1/documents/owner_options?only_existing=true"
            />
          </div>
        </div>
        {loadingDocuments ? (
          <div className="text-center">
            <FontAwesomeIcon icon="spinner" spin size="2x" />
          </div>
        ) : (
          renderDocuments()
        )}
        {!loadingDocuments  && documents.length > 0 && (
          renderPagination()
        )}
      </div>
      <div className="modal-footer">
        {selectedDocuments.length > 0 && (
          <div className="alert alert-info my-0 py-2">
            Selected documents will be shared as public links. Recipients won’t need a Brokerkit account to view them.
          </div>
        )}
        <button
          type="button"
          className="btn btn-primary"
          onClick={handleInsertClick}
          disabled={selectedDocuments.length === 0}
        >
          Insert
        </button>
        <button type="button" className="btn btn-danger" data-dismiss="modal" onClick={handleClose}>
          Close
        </button>
      </div>
    </AppModal>
  );
};

SelectDocumentModal.defaultProps = {
  onInsertClick: () => {},
  handleClose:   () => {},
};

SelectDocumentModal.propTypes = {
  containerID:   PropTypes.string.isRequired,
  modalClass:    PropTypes.string,
  dialogClass:   PropTypes.string,
  onInsertClick: PropTypes.func.isRequired,
  handleClose:   PropTypes.func,
};

export default SelectDocumentModal;
