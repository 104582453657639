import qs                     from 'qs';
import React                  from 'react';
import ReactDOM from          'react-dom';
import autoBind from          'react-autobind';
import { PropTypes }          from 'prop-types';
import { Container }          from 'flux/utils';
import { FontAwesomeIcon }    from '@fortawesome/react-fontawesome';
import Tabs                   from 'react-bootstrap/Tabs';
import Tab                    from 'react-bootstrap/Tab';

import LeadDrawerActions              from '~/actions/lead_drawer_actions';
import LeadDrawerStore                from '~/stores/lead_drawer_store';
import { checkFeatureFlag }           from '~/helpers/FeatureFlagChecker';

import Drawer                           from '~/components/drawers/drawer';
import ResumeDrawer                     from '~/components/drawers/lead_drawer/ResumeDrawer';
import Dialer                           from '~/components/modals/dialer';
import LeadDrawerHeader                 from './lead_drawer/lead_drawer_header';
import LeadDrawerNotes                  from './lead_drawer/lead_drawer_notes';
import LeadDrawerLabels                 from './lead_drawer/lead_drawer_labels';
import LeadDrawerForms, {
  isFormNameValid,
}                                       from './lead_drawer/lead_drawer_forms';
import LeadDrawerTimeline               from './lead_drawer/lead_drawer_timeline';
import LeadDrawerCampaignSubscriptions  from './lead_drawer/lead_drawer_campaign_subscriptions';
import LeadDrawerMainDetails            from './lead_drawer/details/lead_drawer_main_details';
import LeadDrawerRecruitingDetails      from './lead_drawer/details/lead_drawer_recruiting_details';
import LeadDrawerRelitixDetails         from './lead_drawer/details/lead_drawer_relitix_details';
import LeadDrawerAgentAlerts            from './lead_drawer/details/LeadDrawerAgentAlerts/LeadDrawerAgentAlerts';
import LeadDrawerRetentionDetails       from './lead_drawer/details/lead_drawer_retention_details';
import LeadDrawerPipelineDetails        from './lead_drawer/details/lead_drawer_pipeline_details';
import LeadDrawerSocialLinks            from './lead_drawer/details/lead_drawer_social_links';
import LeadDrawerTasks                  from './lead_drawer/details/lead_drawer_tasks';
import LeadDrawerDocuments              from './lead_drawer/details/lead_drawer_documents';

/**
 * Class LeadDrawer - and going to be exported as a state Container
 * @extends React.Component
 */

class LeadDrawer extends React.Component {
  static getStores() {
    return [LeadDrawerStore];
  }

  /**
   * Determine when to re-render LeadDrawer container
   * @param prevState
   * @returns {Object} Store current state
   */
  static calculateState(prevState) {
    return {
      leadDrawerState: LeadDrawerStore.getState(),
    };
  }

  isResumeTabEnabled = checkFeatureFlag(process.env.RESUME_TAB_FLAG || true);

  isDmsEnabled = checkFeatureFlag(process.env.ENABLE_DOCUMENT_MANAGEMENT_FLAG);

  constructor(props) {
    super(props);

    this.state = {
      dialer: {
        id:          null,
        body:        '',
        callSid:     '',
        callStatus:  '',
      },
      activeKey:     'timeline',
      isDialerOpen:  false,
    };

    this.switchForm = this.switchForm.bind(this);
    this.loadLead = this.loadLead.bind(this);
    this.recordIndex = this.recordIndex.bind(this);
    this.handlePrevClick = this.handlePrevClick.bind(this);
    this.handleNextClick = this.handleNextClick.bind(this);
    this.onLeadDrawerStoreChange = this.onLeadDrawerStoreChange.bind(this);
    this.handleOpenDialer = this.handleOpenDialer.bind(this);
    this.setDialerField = this.setDialerField.bind(this);

    autoBind(this);
  }

  componentDidMount() {
    const { defaultAction } = this.props;
    this.loadLead(this.props);

    if (defaultAction) {
      // someone wanted to open to this action, honor the request and force it
      window.location.hash = `#${defaultAction}`;
    }

    if (window.location.hash) {
      // hash set from url, wasn't being passed from somewhere
      this.switchForm(window.location.hash.replace('#', ''));
    }

    // listen to LeadDrawerStore change events and store reference
    this.listener = LeadDrawerStore.addListener(
      this.onLeadDrawerStoreChange,
    );
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.loadLead(nextProps);
  }

  componentWillUnmount() {
    LeadDrawerActions.unloadLead();

    if (this.listener) this.listener.remove();
  }

  handlePrevClick() {
    const { leadDrawerState } = this.state;
    const { query, rowIdx } = this.props;
    const { recordIndex } = leadDrawerState;

    let index = recordIndex;
    if (typeof index === 'undefined') {
      index = this.recordIndex(query, rowIdx);
    }

    let prevRecordIndex = index - 1;
    if (prevRecordIndex < 0) prevRecordIndex = 0;

    LeadDrawerActions.loadLeadByIndex(query, prevRecordIndex);
  }

  handleNextClick() {
    const { leadDrawerState } = this.state;
    const { query, totalCount, rowIdx } = this.props;
    const { recordIndex } = leadDrawerState;

    let index = recordIndex;
    if (typeof index === 'undefined') {
      index = this.recordIndex(query, rowIdx);
    }

    let nextRecordIndex = index + 1;
    if (nextRecordIndex > totalCount - 1) {
      nextRecordIndex = totalCount - 1;
    }

    LeadDrawerActions.loadLeadByIndex(query, nextRecordIndex);
  }

  handleOpenDialer(phoneNumber) {
    const { currentForm, leadDrawerState } = this.state;
    const { lead } = leadDrawerState;

    this.setState({ isDialerOpen: true });

    ReactDOM.render(
      <Dialer
        containerID="dialer-modal"
        modalClass="modal no-pointer-events"
        dialogClass="modal-dialog modal-sm"
        lead={lead}
        leadPhone={phoneNumber}
        currentForm={currentForm}
        switchForm={this.switchForm}
        setDialerField={this.setDialerField}
        onDialerClose={this.enableDrawerControls}
      />,
      document.getElementById('dialer-modal'),
    );
  }

  onLeadDrawerStoreChange() {
    const { lead, leadDrawerStoreAction } = LeadDrawerStore.getState();

    if (!lead) return;

    const params = GlobalContainer.urlParams();
    params.lead = lead.id;

    const searchQuery = qs.stringify(
      Object.assign(params, { _t: Rails.helpers.currentTeam.id }),
      { arrayFormat: 'brackets' },
    );

    // push browser state to opening lead URL
    browserHistory.push(
      `${window.location.pathname}?${decodeURI(searchQuery)}${
        window.location.hash
      }`,
      { lead: lead.id },
    );

    if (
      leadDrawerStoreAction === 'createLeadActivityDone'
      || leadDrawerStoreAction === 'updateLeadActivityDone'
    ) {
      this.setState({
        dialer: {
          id:         null,
          body:       '',
          callSid:    '',
          callStatus: '',
        },
      });
    }
  }

  setDialerField(dialer) {
    this.setState((prevState) => ({
      dialer: {
        ...prevState.dialer,
        id:         dialer.id,
        body:       dialer.body,
        callSid:    dialer.callSid,
        callStatus: dialer.callStatus,
      },
    }));
  }

  enableDrawerControls = () => {
    this.setState({ isDialerOpen: false });
  }

  setCurrentFormWithData = (currentFormDetails) => {
    const newState = { currentForm: currentFormDetails.form };

    if (currentFormDetails.form === 'email') {
      newState.email = currentFormDetails.data.object;
      newState.schedule = currentFormDetails.data.schedule;
    }

    if (currentFormDetails.form === 'text-message') {
      newState.smsMessage = currentFormDetails.data.object;
      newState.schedule = currentFormDetails.data.schedule;
    }

    this.containerEle.scrollTo(0, 0);

    this.setState(newState);
  }

  /**
   * Calculate current recordIndex based on query.page, query.per and current row index
   * @param {Object} query - Current scope query
   * @param rowIdx - Current row index of lead in the table
   * @returns {number} recordIndex - Calculated full record index of the data set
   */
  recordIndex(query, rowIdx) {
    return (query.page - 1) * query.per + rowIdx;
  }

  loadLead(props) {
    const { lead, loadLeadID } = props;

    let leadID = loadLeadID;
    if (lead) leadID = lead.id;

    const params = GlobalContainer.urlParams();
    params.lead = leadID;

    const searchQuery = qs.stringify(
      Object.assign(params, { _t: Rails.helpers.currentTeam.id }),
      { arrayFormat: 'brackets' },
    );

    // push browser state to opening lead URL
    browserHistory.push(
      `${window.location.pathname}?${decodeURI(searchQuery)}${
        window.location.hash
      }`,
      { lead: leadID },
    );

    // Re-load Lead with extra data from Lead property
    if (!lead && loadLeadID) {
      LeadDrawerActions.loadLead(loadLeadID);
    }
  }

  switchForm(formName, resetDialer = true) {
    const { currentForm } = this.state;
    let newForm = formName;

    // invalid form name
    if (isFormNameValid(newForm)) {
      const params = GlobalContainer.urlParams();
      const searchQuery = qs.stringify(params, {
        arrayFormat: 'brackets',
      });

      // clear form select if current form button is selected again
      if (newForm === currentForm) {
        newForm = null;
        // clear current browser state to original path
        browserHistory.push(
          `${window.location.pathname}?${decodeURI(searchQuery)}`,
          {},
        );
      } else {
        // push browser state to opening lead URL
        browserHistory.push(
          `${window.location.pathname}?${decodeURI(searchQuery)}#${formName}`,
        );
      }
    }

    if (newForm && this.timeline && this.timeline.scrollTo) {
      this.timeline.scrollTo(0, 0);
    }

    const newState = { currentForm: newForm };

    if (newForm === 'email') {
      newState.email = null;
      newState.schedule = null;
    }

    if (newForm === 'text-message') {
      newState.smsMessage = null;
      newState.schedule = null;
    }

    if (resetDialer) {
      newState.dialer = {
        id:         null,
        body:       '',
        callSid:    '',
        callStatus: '',
      };
    }

    this.setState(newState);
  }

  renderMainSection(lead, query, currentForm) {
    const { leadDrawerState } = this.state;

    return (
      <div
        className="order-md-2 lead-drawer-details-panel col-md-4 bg-grey-lightest bl bb"
        style={{ minHeight: '85vh' }}
      >
        <div className="pt15">
          <LeadDrawerMainDetails
            key={`main-details-${lead.id}`}
            lead={lead}
            query={query}
          />
        </div>

        <div className="bt pt15">
          <LeadDrawerTasks
            {...leadDrawerState}
            key={`tasks-details-${lead.id}`}
            lead={lead}
            query={query}
          />
        </div>

        {this.isDmsEnabled && (
          <div className="bt pt15">
            <LeadDrawerDocuments
              {...leadDrawerState}
              key={`documents-details-${lead.id}`}
              leadId={lead.id}
              query={query}
            />
          </div>
        )}

        {GlobalContainer.product() === 'retention' ? (
          <div>
            <div className="bt pt15">
              <LeadDrawerRetentionDetails
                key={`retention-details-${lead.id}`}
                lead={lead}
                query={query}
              />
            </div>

            <div className="bt pt15">
              <LeadDrawerRecruitingDetails
                key={`recruiting-details-${lead.id}`}
                lead={lead}
                query={query}
              />
            </div>

            <div className="bt pt15">
              <LeadDrawerRelitixDetails
                key={`relitix-details-${lead.id}`}
                lead={lead}
              />
            </div>

            <div className="bt pt15">
              <LeadDrawerAgentAlerts
                key={`agent-alerts-${lead.id}`}
                lead={lead}
              />
            </div>
          </div>
        ) : (
          <div>
            <div className="bt pt15">
              <LeadDrawerRecruitingDetails
                key={`recruiting-details-${lead.id}`}
                lead={lead}
                query={query}
              />
            </div>

            <div className="bt pt15">
              <LeadDrawerRelitixDetails
                key={`relitix-details-${lead.id}`}
                lead={lead}
              />
            </div>

            <div className="bt pt15">
              <LeadDrawerAgentAlerts
                key={`agent-alerts-${lead.id}`}
                lead={lead}
              />
            </div>

            <div className="bt pt15">
              <LeadDrawerPipelineDetails
                key={`recruiting-details-${lead.id}`}
                lead={lead}
                query={query}
              />
            </div>
          </div>
        )}

        <div className="bt pt15 pb15">
          <LeadDrawerSocialLinks
            key={`social-links-${lead.id}`}
            lead={lead}
          />
        </div>
      </div>
    );
  }

  renderFormSection() {
    const {
      currentForm, leadDrawerState, dialer, activeKey, email, smsMessage, schedule,
    } = this.state;

    return (
      <div
        className="pl0 pr0 order-md-1 col-md-8"
        ref={(el) => (this.timeline = el)}
      >
        {currentForm && (
          <div className="pt15 pb15 bg-grey-lightest bb">
            <LeadDrawerForms
              currentForm={currentForm}
              dialer={dialer}
              {...leadDrawerState}
              email={email}
              smsMessage={smsMessage}
              schedule={schedule}
            />
          </div>
        )}

        <Tabs
          id="lead_drawer_tabs"
          activeKey={activeKey}
          onSelect={(k) => this.setState({ activeKey: k })}
          className="px-2 data-tabs clearfix mt10"
          mountOnEnter
          unmountOnExit
        >
          <Tab eventKey="timeline" title="Timeline" className="p10">
            <>
              <div className="bb p15">
                <LeadDrawerLabels {...leadDrawerState} />
              </div>

              <div className="bb p15">
                <LeadDrawerNotes {...leadDrawerState} />
              </div>

              <div className="bb p15">
                <LeadDrawerCampaignSubscriptions {...leadDrawerState} />
              </div>

              <div className="p15">
                <div className="timeline">
                  <LeadDrawerTimeline
                    setCurrentFormWithData={this.setCurrentFormWithData}
                    {...leadDrawerState}
                  />
                </div>
              </div>
            </>
          </Tab>

          { this.isResumeTabEnabled && (
            <Tab eventKey="resume" title="Resume" className="p1">
              <ResumeDrawer />
            </Tab>
          )}
        </Tabs>
      </div>
    );
  }

  render() {
    const {
      query,
      totalCount,
      rowIdx,
      reportItemIndex,
      recordsLength,
      handleReportPrevClick,
      handleReportNextClick,
    } = this.props;

    const { currentForm, leadDrawerState, isDialerOpen } = this.state;
    const { lead, loadingLead, recordIndex } = leadDrawerState;

    let showNextPrev;
    let prevClickMethod;
    let nextClickMethod;

    // if records query is present and has marked currentIndex or item is a row - assign prev/next handling
    if (
      lead
      && query
      && (recordIndex || typeof rowIdx === 'number')
    ) {
      let index = recordIndex;
      if (typeof index === 'undefined') {
        index = this.recordIndex(query, rowIdx);
      }

      showNextPrev = true;
      if (index > 0) prevClickMethod = this.handlePrevClick;
      if (index < totalCount - 1) {
        nextClickMethod = this.handleNextClick;
      }
    }

    if (typeof reportItemIndex === 'number') {
      showNextPrev = true;
      if (reportItemIndex > 0) {
        prevClickMethod = handleReportPrevClick;
      }
      if (reportItemIndex < recordsLength) {
        nextClickMethod = handleReportNextClick;
      }
    }

    if (loadingLead && !lead) {
      return (
        <Drawer size="wide">
          <div className="row pl15">
            <div className="col-xl-9 col-sm-8 p15">
              <FontAwesomeIcon
                icon="far fa-spinner"
                pulse
                size="lg"
                className="mr5"
              />
              {' '}
              Loading Lead...
            </div>
          </div>
        </Drawer>
      );
    }

    if (!loadingLead && !lead) {
      return (
        <Drawer size="wide">
          <div className="row pl15">
            <div className="col-xl-9 col-sm-8 p15">
              Lead Not Found
            </div>
          </div>
        </Drawer>
      );
    }

    let drawerContent = (
      <>
        {this.renderMainSection(lead, query, currentForm)}
        {this.renderFormSection()}
      </>
    );

    if (Rails.isMobile) {
      drawerContent = (
        <>
          {this.renderFormSection()}
          {this.renderMainSection(lead, query, currentForm)}
        </>
      );
    }

    return (
      <Drawer
        size="wide"
        item={lead}
        reportItemIndex={reportItemIndex}
        showNextPrev={showNextPrev}
        handlePrevClick={(!loadingLead ? prevClickMethod : null)}
        handleNextClick={(!loadingLead ? nextClickMethod : null)}
        isDialerOpen={isDialerOpen}
        scrollable
      >
        <LeadDrawerHeader
          {...leadDrawerState}
          query={query}
          currentForm={currentForm}
          switchForm={this.switchForm}
          handleOpenDialer={this.handleOpenDialer}
        />

        <div className="row" style={{ overflowY: 'scroll' }} ref={(el) => (this.containerEle = el)}>
          {drawerContent}
        </div>
      </Drawer>
    );
  }
}

LeadDrawer.defaultProps = {
  defaultAction:         null,
  lead:                  null,
  loadLeadID:            null, // if loadLeadID presents, load it
  table:                 null,
  query:                 null,
  totalCount:            0,
  rowIdx:                null,
  reportItemIndex:       null,
  recordsLength:         null,
  handleReportPrevClick: () => false,
  handleReportNextClick: () => false,
};

LeadDrawer.propTypes = {
  defaultAction:         PropTypes.string,
  lead:                  PropTypes.shape({}),
  loadLeadID:            PropTypes.number,
  table:                 PropTypes.shape({}),
  query:                 PropTypes.shape({}),
  totalCount:            PropTypes.number,
  rowIdx:                PropTypes.number,
  reportItemIndex:       PropTypes.number,
  recordsLength:         PropTypes.number,
  handleReportPrevClick: PropTypes.func,
  handleReportNextClick: PropTypes.func,
};

/**
 * Export LeadDrawer as a Container
 * @type {Container}
 */
export default Container.create(LeadDrawer);
