import React, { useState, useEffect, useRef } from 'react';
import { Link, useLocation }                  from 'react-router-dom';
import { FontAwesomeIcon }                    from '@fortawesome/react-fontawesome';
import { Tooltip }                            from 'reactstrap';
import classNames                             from 'classnames';
import { checkFeatureFlag }                   from '~/helpers/FeatureFlagChecker';

const AgentSideBar = () => {
  const { abilities } = Rails;
  const [isInboxTooltipVisible, setIsInboxTooltipVisible] = useState(false);
  const [dropdowns, setDropdowns] = useState({
    content:    false,
    leadGen:    false,
    automation: false,
    data:       false,
  });
  const isDmsAllowed = checkFeatureFlag(process.env.ENABLE_DOCUMENT_MANAGEMENT_FLAG);

  const innerLinks = [
    /documents/,
    '/recruiter/team/landing_page/edit',
    '/onboard/referrers',
    '/recruiter/campaigns',
    '/recruiter/campaign_libraries',
    '/recruiter/campaign_triggers',
    '/recruiter/data',
    '/recruiter/labels',
  ];

  const inboxLinkRef = useRef(null);
  const tooltipRef = useRef(null);

  const location = useLocation();
  const page = location.pathname;

  const handleInboxClick = (e) => {
    const isAccessAllowed = checkFeatureFlag(process.env.RESTRICT_INBOX_ACCESS_FLAG);
    if (!isAccessAllowed) {
      e.preventDefault();
      setIsInboxTooltipVisible(true);
    } else {
      setIsInboxTooltipVisible(false);
    }
  };

  const handleOutsideClick = (e) => {
    if (inboxLinkRef.current && !inboxLinkRef.current.contains(e.target)
        && tooltipRef.current && !tooltipRef.current.contains(e.target)) {
      setIsInboxTooltipVisible(false);
    }
  };

  const toggleDropdown = (name) => {
    setDropdowns((prev) => ({ ...prev, [name]: !prev[name] }));
  };

  function isActivePage(pathOrRegex) {
    let isActive = false;

    if (Array.isArray(pathOrRegex)) {
      isActive = pathOrRegex.some((p) => (typeof p === 'string' ? page === p : p.test(page)));
    } else if (typeof pathOrRegex === 'string') {
      isActive = page === pathOrRegex;
    } else {
      isActive = pathOrRegex.test(page);
    }

    return isActive;
  }

  const isDropdown = (pathOrRegex) => {
    if (Array.isArray(pathOrRegex)) {
      return pathOrRegex.some((p) => innerLinks.some((l) => (typeof l === 'string' ? l === p : l.source === p.source)));
    } if (typeof pathOrRegex === 'string') {
      return innerLinks.some((l) => l === pathOrRegex);
    }
    return innerLinks.some((l) => l.source === pathOrRegex.source);
  };

  const getClassName = (pathOrRegex) => {
    const active = isActivePage(pathOrRegex);
    let baseClass = 'list-group-item justify-content-between';
    const dropDown = isDropdown(pathOrRegex);
    if (dropDown) {
      baseClass += ' pl30 pr-3';
    }

    return classNames(baseClass, {
      active,
    });
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleOutsideClick);

    setDropdowns({
      content:    isActivePage(/documents/),
      leadGen:    isActivePage([/landing_page/, '/onboard/referrers']),
      automation: isActivePage(/campaign/),
      data:       isActivePage([/data/, /labels/, /search_queries/, /configure_mls/]),
    });

    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
    };
  }, [page]);

  const innerLinkStyles = { width: 'fit-content', minWidth: '100%' };
  const dropdownStyles = {
    background: 'none',
    border:     'none',
    padding:    '10',
    width:      '100%',
    textAlign:  'left',
  };

  return (
    <div className="sidebar list-group text-nowrap">
      <Link to="/recruiter/today" className={getClassName('/recruiter/today')}>
        <FontAwesomeIcon icon={['fas', 'fa-calendar-day']} className="fa-lg position-absolute" />
        <span className="ml40">Today</span>
      </Link>

      <>
        <Link
          id="inbox-link"
          to="/recruiter/inbox"
          className={getClassName('/recruiter/inbox')}
          onClick={handleInboxClick}
        >
          <FontAwesomeIcon icon={['fas', 'fa-inbox']} className="fa-lg position-absolute" />
          <span className="ml40">Inbox</span>
        </Link>

        <Tooltip
          placement="bottom"
          isOpen={isInboxTooltipVisible}
          target="inbox-link"
          autohide={false}
          toggle={() => { }}
          innerRef={tooltipRef}
        >
          <>
            It looks like you&apos;re trying to access the inbox — great choice! Please upgrade your pricing plan
            {' '}
            <a
              ref={inboxLinkRef}
              href="/billing"
              target="_blank"
              rel="noopener noreferrer"
              className="text-success"
            >
              here
            </a>
            {' '}
            to enable this feature.
          </>
        </Tooltip>
      </>

      <Link to="/recruiter/leads" className={getClassName('/recruiter/leads')}>
        <FontAwesomeIcon icon={['fas', 'fa-address-book']} className="fa-lg position-absolute" />
        <span className="ml40">Contacts</span>
      </Link>

      <Link to="/recruiter/tasks_due_today" className={getClassName(/tasks/)}>
        <FontAwesomeIcon icon={['fas', 'fa-tasks']} className="fa-lg position-absolute" />
        <span className="ml40">Tasks</span>
      </Link>

      <>
        <a
          className="list-group-item justify-content-between"
          onClick={() => toggleDropdown('content')}
          style={dropdownStyles}
        >
          <FontAwesomeIcon icon={['far', 'fa-photo-video']} className="fa-lg position-absolute" />
          <span className="ml40">Content</span>
          <FontAwesomeIcon
            icon={['fas', dropdowns.content ? 'fa-caret-up' : 'fa-caret-down']}
            className="fa-sm position-absolute mt-1 ml-2"
          />
        </a>

        {dropdowns.content && isDmsAllowed && (
          <div>
            <Link to="/recruiter/documents" className={getClassName(/documents/)} style={innerLinkStyles}>
              <FontAwesomeIcon icon={['fal', 'fa-folder-open']} className="fa-lg position-absolute" />
              <span className="ml30">Documents</span>
            </Link>
          </div>
        )}
      </>

      <a
        className="list-group-item justify-content-between"
        onClick={() => toggleDropdown('leadGen')}
        style={dropdownStyles}
      >
        <FontAwesomeIcon icon={['far', 'fa-bullseye-pointer']} className="fa-lg position-absolute" />
        <span className="ml40">Lead Gen</span>
        <FontAwesomeIcon
          icon={['fas', dropdowns.leadGen ? 'fa-caret-up' : 'fa-caret-down']}
          className="fa-sm position-absolute mt-1 ml-2"
        />
      </a>

      {dropdowns.leadGen && (
        <>
          {abilities.manageLandingPages && (
            <Link to="/recruiter/team/landing_page/edit" className={getClassName('/recruiter/team/landing_page/edit')} style={innerLinkStyles}>
              <FontAwesomeIcon icon={['fas', 'fa-file-alt']} className="fa-lg position-absolute" />
              <span className="ml30">Landing Pages</span>
            </Link>
          )}
          {abilities.manageCurrentTeam && (
            <Link to="/onboard/referrers" className={getClassName('/onboard/referrers')} style={innerLinkStyles}>
              <FontAwesomeIcon icon={['fad', 'fa-handshake-alt']} className="fa-lg position-absolute" />
              <span className="ml30">Referrals</span>
            </Link>
          )}
        </>
      )}

      <a
        className="list-group-item justify-content-between"
        onClick={() => toggleDropdown('automation')}
        style={dropdownStyles}
      >
        <FontAwesomeIcon icon={['fal', 'fa-magic']} className="fa-lg position-absolute" />
        <span className="ml40">Automation</span>
        <FontAwesomeIcon
          icon={['fas', dropdowns.automation ? 'fa-caret-up' : 'fa-caret-down']}
          className="fa-sm position-absolute mt-1 ml-2"
        />
      </a>

      {dropdowns.automation && abilities.manageCampaigns && (
        <>
          <Link
            to="/recruiter/campaigns"
            className={getClassName(['/recruiter/campaigns', /campaigns\/new/, /campaigns\/\d+\/edit/])}
            style={innerLinkStyles}
          >
            <FontAwesomeIcon icon={['fas', 'fa-bullhorn']} className="fa-lg position-absolute" />
            <span className="ml30">Campaigns</span>
          </Link>
          <Link
            to="/recruiter/campaign_libraries"
            className={getClassName(['/recruiter/campaign_libraries', /campaign_libraries\/\d+$/, /campaign_libraries\/\d+\/edit/])}
            style={innerLinkStyles}
          >
            <FontAwesomeIcon icon={['fal', 'fa-books']} className="fa-lg position-absolute" />
            <span className="ml30">Campaign Library</span>
          </Link>
          <Link
            to="/recruiter/campaign_triggers"
            className={getClassName(['/recruiter/campaign_triggers', /campaign_triggers\/new/, /campaign_triggers\/\d+\/edit/])}
            style={innerLinkStyles}
          >
            <FontAwesomeIcon icon={['fal', 'fa-bolt']} className="fa-lg position-absolute" />
            <span className="ml30">Campaign Triggers</span>
          </Link>
        </>
      )}

      <a
        className="list-group-item justify-content-between"
        onClick={() => toggleDropdown('data')}
        style={dropdownStyles}
      >
        <FontAwesomeIcon icon={['fas', 'fa-database']} className="fa-lg position-absolute" />
        <span className="ml40">Data</span>
        <FontAwesomeIcon
          icon={['fas', dropdowns.data ? 'fa-caret-up' : 'fa-caret-down']}
          className="fa-sm position-absolute mt-1 ml-2"
        />
      </a>
      {dropdowns.data && (
        <>
          {abilities.manageLeadSources && (
            <Link
              to="/recruiter/data"
              className={getClassName(['/recruiter/data', /search_queries/, /configure_mls/])}
              style={innerLinkStyles}
            >
              <FontAwesomeIcon icon={['far', 'fa-user-plus']} className="fa-lg position-absolute" />
              <span className="ml30">Contact Import</span>
            </Link>
          )}
          {abilities.manageLabels && (
            <Link
              to="/recruiter/labels"
              className={getClassName('/recruiter/labels')}
              style={innerLinkStyles}
            >
              <FontAwesomeIcon icon={['fas', 'fa-tags']} className="fa-lg position-absolute" />
              <span className="ml30">Labels</span>
            </Link>
          )}
        </>
      )}

      <Link to="/recruiter/reports" className={getClassName('/recruiter/reports')}>
        <FontAwesomeIcon icon={['fas', 'fa-chart-line']} className="fa-lg position-absolute" />
        <span className="ml40">Reports</span>
      </Link>
    </div>
  );
};

AgentSideBar.defaultProps = {};
AgentSideBar.propTypes = {};

export default AgentSideBar;
