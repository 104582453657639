import React, { useCallback, useState } from 'react';
import ReactDOM                         from 'react-dom';
import SelectDocumentModal              from '~/components/modals/select_document_modal';
import { checkFeatureFlag }             from '~/helpers/FeatureFlagChecker';
import APIRequest                       from '~/lib/api_request';

const InsertDocumentButton = ({ setLinksToInsert }) => {
  const isDmsEnabled = checkFeatureFlag(process.env.ENABLE_DOCUMENT_MANAGEMENT_FLAG);
  const { helpers } = Rails;
  const currentUser = helpers?.currentUser || {};
  const isAdminOrStaff = currentUser?.role?.name === 'admin' || currentUser?.role?.name === 'staff';
  const [isModalOpen, setIsModalOpen] = useState(false);

  const openSelectDocModal = useCallback(() => {
    setIsModalOpen(true);
  }, []);

  const closeSelectDocModal = useCallback(() => {
    setIsModalOpen(false);
  }, []);

  const onInsertDocument = useCallback((documents) => {
    const handleInsert = async () => {
      closeSelectDocModal();

      const { origin } = window.location;
      const token = await getToken();

      if (!token) return;

      const docLinks = documents.map((doc) => ({
        href: `${origin}/d/${doc.id}/?auth=${token}`,
        text: doc.title,
      }));

      setLinksToInsert(docLinks);
    };

    handleInsert();
  }, [setLinksToInsert]);

  const getToken = async () => {
    let token = null;
    try {
      const res = await APIRequest.get({ resource: '/v1/auth/token/document' }).then();
      if (res.body.token) {
        token = res.body.token;
      } else {
        GlobalContainer.error(
          'Unable to authenticate for document, please contact support',
          'error',
        );
      }
    } catch (err) {
      console.error('Failed to get token: ', err);
    }
    return token;
  };

  if (!isDmsEnabled || !isAdminOrStaff) return null;

  return (
    <>
      <button type="button" className="btn btn-secondary mb-1" onClick={openSelectDocModal}>
        + Insert Document
      </button>
      {isModalOpen && (
        ReactDOM.createPortal(
          <SelectDocumentModal
            containerID="secondary-modal"
            modalClass="modal modal-overlay"
            dialogClass="modal-dialog modal-dialog-centered modal-xl"
            onInsertClick={onInsertDocument}
            handleClose={closeSelectDocModal}
          />,
          document.getElementById('secondary-modal'),
        )
      )}
    </>
  );
};

export default InsertDocumentButton;
