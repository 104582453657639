import { text } from '@fortawesome/fontawesome-svg-core';
import AppDispatcher                 from '~/dispatchers/app_dispatcher';
import APIRequest, { axiosInstance } from '~/lib/api_request';
import AgentActions                  from '~/actions/agent_actions';
import AppointmentActions            from '~/actions/appointment_actions';
import LeadDrawerActionTypes         from '~/types/lead_drawer_action_types';
import LeadDrawerStore               from '~/stores/lead_drawer_store';
import GlobalContainer             from '~/components/global_container';

let leadDrawerRequest;
let leadDrawerUpdateRequest;
let leadDrawerFormsRequest;
let updateLeadDrawerFormsRequest;
let leadDrawerLoadByIndexRequest;

export const commonFlashMessages = {
  errorMessage:                 'Something went wrong. Please try again.',
  emailScheduleSuccess:         'Email will be sent at the scheduled time.',
  emailScheduleCanceledSuccess: 'Email schedule cancelled successfully.',
  textScheduleSuccess:          'Text will be sent at the scheduled time.',
  textScheduleCanceledSuccess:  'Text schedule cancelled successfully.',
};

/**
 * Lead Drawer action object.
 * @type {Object}
 */
const LeadDrawerActions = {
  /**
   * Create a lead.
   * @param {Object} lead - Lead data to create
   */
  createLead(lead) {
    AppDispatcher.dispatch({
      type: LeadDrawerActionTypes.CREATE_LEAD,
    });

    APIRequest.abort(leadDrawerRequest);

    leadDrawerRequest = APIRequest.post({
      resource: '/v1/leads',
      data:     {
        lead,
      },
    });

    leadDrawerRequest.end((error, response) => {
      if (error) {
        AppDispatcher.dispatch({
          type:   LeadDrawerActionTypes.CREATE_LEAD_FAIL,
          errors: response.body,
        });

        return;
      }

      AppDispatcher.dispatch({
        type: LeadDrawerActionTypes.CREATE_LEAD_DONE,
        lead: response.body,
      });

      // reload today table after new lead created - but don't be too hassle
      AgentActions.reloadLeadTables();
    });
  },

  /**
   * Create a referral lead.
   * @param {Object} referral lead - Lead data to create
   */
  createReferralLead(lead) {
    AppDispatcher.dispatch({
      type: LeadDrawerActionTypes.CREATE_LEAD,
    });

    APIRequest.abort(leadDrawerRequest);

    leadDrawerRequest = APIRequest.post({
      resource: '/v1/referrals',
      data:     {
        lead,
      },
    });

    leadDrawerRequest.end((error, response) => {
      if (error) {
        AppDispatcher.dispatch({
          type:   LeadDrawerActionTypes.CREATE_LEAD_FAIL,
          errors: response.body,
        });

        return;
      }

      AppDispatcher.dispatch({
        type: LeadDrawerActionTypes.CREATE_LEAD_DONE,
        lead: response.body,
      });

      // reload today table after new lead created - but don't be too hassle
      AgentActions.reloadLeadTables();
    });
  },

  /**
   * Load a lead.
   * @param {string|number} leadID - Lead id to load
   * @param {Object} options - Extra load options, support: recordIndex
   */
  loadLead(leadID, options = {}) {
    AppDispatcher.dispatch({
      type: LeadDrawerActionTypes.LOAD_LEAD,
    });

    APIRequest.abort(leadDrawerRequest);

    leadDrawerRequest = APIRequest.get({
      resource: `/v1/leads/${leadID}`,
    });

    leadDrawerRequest.end((error, response) => {
      if (error) {
        AppDispatcher.dispatch({
          type: LeadDrawerActionTypes.LOAD_LEAD_FAIL,
          error,
        });

        return;
      }

      AppDispatcher.dispatch({
        type:        LeadDrawerActionTypes.LOAD_LEAD_DONE,
        lead:        response.body,
        recordIndex: options.recordIndex,
      });
    });
  },

  /**
   * Load a lead by record index.
   * @param {Object} tableQuery - Current table query object
   * @param {number} recordIndex - Load record at the index
   */
  loadLeadByIndex(tableQuery, recordIndex) {
    AppDispatcher.dispatch({
      type: LeadDrawerActionTypes.LOAD_LEAD,
    });

    APIRequest.abort(leadDrawerLoadByIndexRequest);

    const query = { ...tableQuery };
    // calculate current page from recordIndex
    query.page = Math.floor(recordIndex / query.per) + 1;
    query.id_only = true; // fetch only record IDs for faster operation

    let resource = '/v1/leads';
    if (query.lead_scope === 'search_results') {
      resource = '/v1/search';
    }

    if (
      query.lead_scope === 'today_or_past_tasks'
      || query.lead_scope === 'tasks_due_later'
      || query.lead_scope === 'tasks_overdue'
      || query.lead_scope === 'all_tasks'
    ) {
      resource = '/v1/task_leads';
    }

    leadDrawerLoadByIndexRequest = APIRequest.get({
      resource,
      data: query,
    });

    leadDrawerLoadByIndexRequest.end((error, response) => {
      if (error) {
        AppDispatcher.dispatch({
          type: LeadDrawerActionTypes.LOAD_LEAD_FAIL,
          error,
        });

        return;
      }

      if (response.body.length > 0) {
        const leadID = response.body[recordIndex];

        LeadDrawerActions.loadLead(leadID, { recordIndex });
      }
    });
  },

  /**
   * Update Lead
   * @param {Object} lead - Lead data to be updated
   */
  updateLead(lead) {
    AppDispatcher.dispatch({
      type: LeadDrawerActionTypes.UPDATE_LEAD,
    });

    APIRequest.abort(leadDrawerUpdateRequest);

    leadDrawerUpdateRequest = APIRequest.put({
      resource: `/v1/leads/${lead.id}`,
      data:     {
        lead,
      },
    });

    leadDrawerUpdateRequest.end((error, response) => {
      if (error) {
        AppDispatcher.dispatch({
          type:   LeadDrawerActionTypes.UPDATE_LEAD_FAIL,
          errors: response.body,
        });

        return;
      }

      AppDispatcher.dispatch({
        type: LeadDrawerActionTypes.UPDATE_LEAD_DONE,
        lead: response.body,
      });

      AgentActions.reloadLeadTables();
    });
  },

  updateAvatar(lead) {
    const formData = new FormData();
    const config = {
      headers: { 'Content-Type': 'multipart/form-data' },
    };
    formData.append('lead[photo_url]', lead.photo_url);

    AppDispatcher.dispatch({
      type: LeadDrawerActionTypes.UPDATE_LEAD_AVATAR,
    });

    axiosInstance
      .put(`/v1/leads/${lead.id}`, formData, config)
      .then((response) => {
        AppDispatcher.dispatch({
          type: LeadDrawerActionTypes.UPDATE_LEAD_AVATAR_DONE,
          lead: response.data,
        });
      })
      .catch((error) => {
        AppDispatcher.dispatch({
          type:   LeadDrawerActionTypes.UPDATE_LEAD_AVATAR_FAIL,
          errors: error.response.data,
        });
      });
  },

  removeAvatar(lead) {
    AppDispatcher.dispatch({
      type: LeadDrawerActionTypes.REMOVE_LEAD_AVATAR,
    });

    APIRequest.abort(leadDrawerUpdateRequest);

    leadDrawerUpdateRequest = APIRequest.put({
      resource: `/v1/leads/${lead.id}`,
      data:     {
        lead,
      },
    });

    leadDrawerUpdateRequest.end((error, response) => {
      if (error) {
        AppDispatcher.dispatch({
          type:   LeadDrawerActionTypes.REMOVE_LEAD_AVATAR_FAIL,
          errors: response.body,
        });

        return;
      }

      AppDispatcher.dispatch({
        type: LeadDrawerActionTypes.REMOVE_LEAD_AVATAR_DONE,
        lead: response.body,
      });
    });
  },

  inviteLeadToTeam(lead) {
    const { recordIndex } = LeadDrawerStore.getState();

    AppDispatcher.dispatch({
      type: LeadDrawerActionTypes.LEAD_TEAM_MEMBER_INVITE,
    });

    leadDrawerFormsRequest = APIRequest.post({
      resource: `/v1/leads/${lead.id}/invite`,
    });

    leadDrawerFormsRequest.end((error, response) => {
      if (error) {
        AppDispatcher.dispatch({
          type: LeadDrawerActionTypes.LEAD_TEAM_MEMBER_INVITE_FAIL,
          error,
        });

        return;
      }

      AppDispatcher.dispatch({
        type: LeadDrawerActionTypes.LEAD_TEAM_MEMBER_INVITE_DONE,
        lead,
      });

      LeadDrawerActions.loadLead(lead.id, { recordIndex });
    });
  },

  updateLeadRole(lead, role) {
    APIRequest.abort(leadDrawerFormsRequest);

    AppDispatcher.dispatch({
      type:       LeadDrawerActionTypes.UPDATE_LEAD_ROLE,
      teamMember: lead.team_member,
    });

    leadDrawerFormsRequest = APIRequest.put({
      resource: `/v1/team/members/${lead.team_member.id}`,
      data:     {
        lead: {
          lead_emails_attributes: lead.lead_emails_attributes,
        },
        user: lead.team_member,
        id:   lead.team_member,
        role: {
          name: role,
        },
      },
    });

    leadDrawerFormsRequest.end((error, response) => {
      if (error) {
        AppDispatcher.dispatch({
          type: LeadDrawerActionTypes.UPDATE_LEAD_ROLE_FAIL,
          error,
        });

        return;
      }

      AppDispatcher.dispatch({
        type:        LeadDrawerActionTypes.UPDATE_LEAD_ROLE_DONE,
        team_member: response.body,
      });
    });
  },

  createLeadEmail(lead, data) {
    AppDispatcher.dispatch({
      type: LeadDrawerActionTypes.CREATE_LEAD_EMAIL,
    });

    APIRequest.abort(leadDrawerFormsRequest);

    leadDrawerFormsRequest = APIRequest.post({
      resource: `/v1/leads/${lead.id}/emails`,
      data,
    });

    return new Promise((resolve, reject) => {
      leadDrawerFormsRequest.end((error, response) => {
        if (error) {
          AppDispatcher.dispatch({
            type: LeadDrawerActionTypes.CREATE_LEAD_EMAIL_FAIL,
            error,
          });

          const errorMsg = response?.body?.errors || commonFlashMessages.errorMessage;
          // GlobalContainer.notify(errorMsg, 'error');
          reject(error);
          return;
        }

        const { email, inboxMessage, isScheduled } = response.body;

        AppDispatcher.dispatch({
          type:         LeadDrawerActionTypes.CREATE_LEAD_EMAIL_DONE,
          email,
          inboxMessage,
        });

        if (isScheduled) {
          GlobalContainer.notify(commonFlashMessages.emailScheduleSuccess, 'success');
        }

        AgentActions.reloadLeadTables();
        resolve();
      });
    });
  },

  updateLeadEmail(lead, emailId, data) {
    AppDispatcher.dispatch({
      type: LeadDrawerActionTypes.UPDATE_LEAD_EMAIL,
    });

    APIRequest.abort(leadDrawerFormsRequest);

    leadDrawerFormsRequest = APIRequest.put({
      resource: `/v1/leads/${lead.id}/emails/${emailId}`,
      data,
    });

    leadDrawerFormsRequest.end((error, response) => {
      if (error) {
        AppDispatcher.dispatch({
          type: LeadDrawerActionTypes.UPDATE_LEAD_EMAIL_FAIL,
          error,
        });

        const errorMsg = response?.body?.error || commonFlashMessages.errorMessage;

        GlobalContainer.notify(errorMsg, 'error');

        return;
      }

      const { email, inboxMessage, isScheduled } = response.body;

      AppDispatcher.dispatch({
        type: LeadDrawerActionTypes.UPDATE_LEAD_EMAIL_DONE,
        email,
        inboxMessage,
      });

      if (isScheduled) {
        GlobalContainer.notify(commonFlashMessages.emailScheduleSuccess, 'success');
      }

      AgentActions.reloadLeadTables();
    });
  },

  cancelLeadEmail(lead, emailId) {
    AppDispatcher.dispatch({
      type: LeadDrawerActionTypes.CANCEL_SCHEDULED_LEAD_EMAIL,
    });

    APIRequest.abort(leadDrawerFormsRequest);

    leadDrawerFormsRequest = APIRequest.delete({
      resource: `/v1/leads/${lead.id}/emails/${emailId}/cancel_scheduled_email`,
    });

    leadDrawerFormsRequest.end((error, response) => {
      if (error) {
        AppDispatcher.dispatch({
          type: LeadDrawerActionTypes.CANCEL_SCHEDULED_LEAD_EMAIL_FAIL,
          error,
        });

        const errorMsg = response?.body?.error || commonFlashMessages.errorMessage;
        GlobalContainer.notify(errorMsg, 'error');

        return;
      }

      AppDispatcher.dispatch({
        type: LeadDrawerActionTypes.CANCEL_SCHEDULED_LEAD_EMAIL_DONE,
      });

      GlobalContainer.notify(commonFlashMessages.emailScheduleCanceledSuccess, 'success');
    });
  },

  createLeadAppointment(lead, data) {
    AppDispatcher.dispatch({
      type: LeadDrawerActionTypes.CREATE_LEAD_APPOINTMENT,
    });

    APIRequest.abort(leadDrawerFormsRequest);

    leadDrawerFormsRequest = APIRequest.post({
      resource: `/v1/leads/${lead.id}/appointments`,
      data,
    });

    return new Promise((resolve, reject) => {
      leadDrawerFormsRequest.end((error, response) => {
        if (error) {
          AppDispatcher.dispatch({
            type: LeadDrawerActionTypes.CREATE_LEAD_APPOINTMENT_FAIL,
            error,
          });
          const errorMsg = response?.body?.errors || commonFlashMessages.errorMessage;
          // GlobalContainer.notify(errorMsg, 'error');
          reject(error);
          return;
        }
        AppDispatcher.dispatch({
          type: LeadDrawerActionTypes.CREATE_LEAD_APPOINTMENT_DONE,
        });

        AgentActions.reloadLeadTables();
        setTimeout(() => {
          AppointmentActions.loadAppointments();
        }, 500);
        resolve();
      });
    });
  },

  createLeadSmsMessage(lead, data) {
    AppDispatcher.dispatch({
      type: LeadDrawerActionTypes.CREATE_LEAD_SMS_MESSAGE,
    });

    APIRequest.abort(leadDrawerFormsRequest);

    leadDrawerFormsRequest = APIRequest.post({
      resource: `/v1/leads/${lead.id}/sms_messages`,
      data,
    });

    leadDrawerFormsRequest.end((error, response) => {
      if (error) {
        AppDispatcher.dispatch({
          type: LeadDrawerActionTypes.CREATE_LEAD_SMS_MESSAGE_FAIL,
          error,
        });

        const errorMsg = response?.body?.error || commonFlashMessages.errorMessage;
        GlobalContainer.notify(errorMsg, 'error');

        return;
      }

      const { smsMessage, inboxMessage, isScheduled } = response.body;

      AppDispatcher.dispatch({
        type: LeadDrawerActionTypes.CREATE_LEAD_SMS_MESSAGE_DONE,
        smsMessage,
        inboxMessage,
      });

      if (isScheduled) {
        GlobalContainer.notify(commonFlashMessages.textScheduleSuccess, 'success');
      }

      AgentActions.reloadLeadTables();
    });
  },

  updateLeadSmsMessage(lead, textId, data) {
    AppDispatcher.dispatch({
      type: LeadDrawerActionTypes.UPDATE_LEAD_SMS_MESSAGE,
    });

    APIRequest.abort(leadDrawerFormsRequest);

    leadDrawerFormsRequest = APIRequest.put({
      resource: `/v1/leads/${lead.id}/sms_messages/${textId}`,
      data,
    });

    leadDrawerFormsRequest.end((error, response) => {
      if (error) {
        AppDispatcher.dispatch({
          type: LeadDrawerActionTypes.UPDATE_LEAD_SMS_MESSAGE_FAIL,
          error,
        });

        const errorMsg = response?.body?.error || commonFlashMessages.errorMessage;

        GlobalContainer.notify(errorMsg, 'error');

        return;
      }

      const { smsMessage, inboxMessage, isScheduled } = response.body;

      AppDispatcher.dispatch({
        type: LeadDrawerActionTypes.UPDATE_LEAD_SMS_MESSAGE_DONE,
        smsMessage,
        inboxMessage,
      });

      if (isScheduled) {
        GlobalContainer.notify(commonFlashMessages.textScheduleSuccess, 'success');
      }

      AgentActions.reloadLeadTables();
    });
  },

  cancelLeadSmsMessage(lead, textId) {
    AppDispatcher.dispatch({
      type: LeadDrawerActionTypes.CANCEL_SCHEDULED_LEAD_SMS_MESSAGE,
    });

    APIRequest.abort(leadDrawerFormsRequest);

    leadDrawerFormsRequest = APIRequest.delete({
      resource: `/v1/leads/${lead.id}/sms_messages/${textId}/cancel_scheduled_text`,
    });

    leadDrawerFormsRequest.end((error, response) => {
      if (error) {
        AppDispatcher.dispatch({
          type: LeadDrawerActionTypes.CANCEL_SCHEDULED_LEAD_SMS_MESSAGE_FAIL,
          error,
        });

        const errorMsg = response?.body?.error || commonFlashMessages.errorMessage;
        GlobalContainer.notify(errorMsg, 'error');

        return;
      }

      AppDispatcher.dispatch({
        type: LeadDrawerActionTypes.CANCEL_SCHEDULED_LEAD_SMS_MESSAGE_DONE,
      });

      GlobalContainer.notify(commonFlashMessages.textScheduleCanceledSuccess, 'success');
    });
  },

  createLeadActivity(lead, data) {
    AppDispatcher.dispatch({
      type: LeadDrawerActionTypes.CREATE_LEAD_ACTIVITY,
    });

    APIRequest.abort(leadDrawerFormsRequest);

    leadDrawerFormsRequest = APIRequest.post({
      resource: `/v1/leads/${lead.id}/activities`,
      data,
    });

    leadDrawerFormsRequest.end((error, response) => {
      if (error) {
        AppDispatcher.dispatch({
          type: LeadDrawerActionTypes.CREATE_LEAD_ACTIVITY_FAIL,
          error,
        });

        return;
      }

      AppDispatcher.dispatch({
        type:     LeadDrawerActionTypes.CREATE_LEAD_ACTIVITY_DONE,
        activity: response.body,
      });
    });
  },

  updateLeadActivity(lead, id, data) {
    AppDispatcher.dispatch({
      type: LeadDrawerActionTypes.UPDATE_LEAD_ACTIVITY,
    });

    APIRequest.abort(updateLeadDrawerFormsRequest);

    updateLeadDrawerFormsRequest = APIRequest.put({
      resource: `/v1/leads/${lead.id}/activities/${id}`,
      data,
    });

    updateLeadDrawerFormsRequest.end((error, response) => {
      if (error) {
        AppDispatcher.dispatch({
          type: LeadDrawerActionTypes.UPDATE_LEAD_ACTIVITY_FAIL,
          error,
        });

        return;
      }

      AppDispatcher.dispatch({
        type:     LeadDrawerActionTypes.UPDATE_LEAD_ACTIVITY_DONE,
        activity: response.body,
      });
    });
  },

  reassignLead(leadID, teamID) {
    AppDispatcher.dispatch({
      type: LeadDrawerActionTypes.REASSIGN_LEAD,
    });

    APIRequest.abort(leadDrawerFormsRequest);

    leadDrawerFormsRequest = APIRequest.post({
      resource: `/v1/leads/${leadID}/reassign_lead`,
      data:     {
        lead_id: leadID,
        team_id: teamID,
      },
    });

    leadDrawerFormsRequest.end((error, response) => {
      if (error) {
        AppDispatcher.dispatch({
          type:     LeadDrawerActionTypes.REASSIGN_LEAD_FAIL,
          error,
          activity: response.body,
        });

        return;
      }

      AppDispatcher.dispatch({
        type:     LeadDrawerActionTypes.REASSIGN_LEAD_DONE,
        activity: response.body,
      });

      AgentActions.reloadLeadTables();
    });
  },

  unloadLead() {
    AppDispatcher.dispatch({
      type: LeadDrawerActionTypes.UNLOAD_LEAD,
    });
  },

  uploadResume(params, lead) {
    const hasExistingDocument = lead.document?.url;
    const method = hasExistingDocument ? 'put' : 'post';
    const url = hasExistingDocument ? `/v1/documents/${lead.document.id}` : '/v1/documents';
    const defaultType = hasExistingDocument ? 'UPDATE_RESUME' : 'CREATE_RESUME';
    const successType = `${defaultType}_DONE`;
    const failureType = `${defaultType}_FAIL`;

    AppDispatcher.dispatch({
      type: LeadDrawerActionTypes[defaultType],
    });

    let data = { ...params, lead_id: lead.id };
    if (hasExistingDocument) {
      data.document.skip_pdf_conversion = false;
    }

    const uploadResumeRequest = APIRequest[method]({
      resource: url,
      data,
    });

    uploadResumeRequest.end((error, response) => {
      let document = {};
      if (hasExistingDocument) {
        document = response.body;
      } else {
        document = response.body.documents?.[0] || {};
      }

      const updatedLead = {
        ...lead,
        document: {
          ...lead.document,
          id:             document.id,
          url:            document.url,
          title:          document.title,
          document_type:  document.document_type,
        },
      };

      if (error) {
        AppDispatcher.dispatch({
          type:   LeadDrawerActionTypes[failureType],
          errors: error,
        });

        return;
      }

      AppDispatcher.dispatch({
        type: LeadDrawerActionTypes[successType],
        lead: updatedLead,
      });
    });
  },

  deleteResume(lead) {
    AppDispatcher.dispatch({
      type: LeadDrawerActionTypes.DELETE_RESUME,
    });

    const deleteResumeRequest = APIRequest.delete({
      resource: `/v1/documents/${lead.document.id}/move_to_trash`,
    });

    deleteResumeRequest.end((error) => {
      const updatedLead = {
        ...lead,
        document: {
          ...lead.document,
          id:             null,
          url:            null,
          title:          null,
          document_type:  null,
        },
      };

      if (error) {
        AppDispatcher.dispatch({
          type:   LeadDrawerActionTypes.DELETE_RESUME_FAIL,
          errors: error,
        });

        return;
      }

      AppDispatcher.dispatch({
        type:     LeadDrawerActionTypes.DELETE_RESUME_DONE,
        lead:     updatedLead,
      });
    });
  },
};

export default LeadDrawerActions;
