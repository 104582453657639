import autoBind            from 'react-autobind';
import React               from 'react';
import ReactDOM            from 'react-dom';
import numeral             from 'numeral';
import { PropTypes }       from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import AgentActions           from '~/actions/agent_actions';
import BulkSelectActions      from '~/actions/bulk_select_actions';
import AgentStore             from '~/stores/agent_store';
import APIRequest             from '~/lib/api_request';
import DataTableContent       from './data_table/data_table_content';
import TaskModal              from '~/components/modals/task_modal';
import { restrictOwnerScope } from '~/helpers/staffRestrictionHelper';
import DocumentActions from '~/actions/document_actions';
import brokerbit                from '~/lib/brokerbit';
import DocumentStore           from '~/stores/document_store';

class DataTable extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loading:       true,
      referralStats: {
        leads_count: 0,
        joins_count: 0,
        total_value: 0,
      },
      ...this.getScopedTableData(props.table),
    };

    autoBind(this);
  }

  componentDidMount() {
    const { table, params, query } = this.props;
    const urlParams = GlobalContainer.urlParams();

    AgentActions.resetStore();

    this.unmounted = false;
    this.agentStoreListener = AgentStore.addListener(this.onAgentChange);
    this.documentStoreListener = DocumentStore.addListener(this.onDocumentChange);

    BulkSelectActions.deselectLeadItems(table);

    if (params.loadReferrerStats) {
      APIRequest.get({
        resource: '/v1/leads/referrer_stats',
      }).end((err, res) => {
        this.setState({
          referralStats: res.body,
        });
      });
    }

    if (urlParams.taskID) {
      ReactDOM.render(
        <TaskModal
          taskID={urlParams.taskID}
          dialogClass="modal-dialog modal-lg"
          loadLead={false}
        />, document.getElementById('primary-modal'),
      );
    }

    this.setState({ ...this.getScopedTableData(table) }, () => {
      const {
        records, ownerScope, leadScopeSize, documentScopeSize,
      } = this.state;
      let size = leadScopeSize;

      if (table.scope === 'documents' || table.scope === 'trash_documents') {
        const urlParam = GlobalContainer.urlParams();
        size = documentScopeSize;
        if (urlParam.s) {
          return;
        }
      }

      const sort = query?.lead_sort?.split('-');

      AgentActions.loadLeads(table, {
        ownerScope:    restrictOwnerScope(ownerScope),
        page:          query?.page || records?.current_page || 1,
        size,
        searchData:    params.searchData || {},
        sortColumn:    sort?.[0],
        sortDirection: sort?.[1],
      });
    });
  }

  componentWillUnmount() {
    if (this.agentStoreListener) this.agentStoreListener.remove();
    if (this.documentStoreListener) this.documentStoreListener.remove();

    this.unmounted = true;
  }

  onAgentChange = () => {
    const { table } = this.props;
    const tableStoredData = this.getScopedTableData(table);

    if (this.unmounted) return;

    this.setState((prevState) => ({ ...prevState, ...tableStoredData }));
  };

  onDocumentChange = () => {
    const { table } = this.props;

    if (!table.scope.includes('document')) return;

    const tableStoredData = this.getScopedDocumentTableData(table);

    if (tableStoredData == null) return;
    if (this.unmounted) return;

    this.setState((prevState) => ({ ...prevState, ...tableStoredData }));
  };

  handleEmptyTrash = (e) => {
    e.preventDefault();

    brokerbit.confirmBox({
      message:  'Are you sure you want to permanently delete all documents in the trash?',
      callback: (ok) => {
        if (ok) {
          DocumentActions.empty_documents_trash();
        }
      },
    });
  };

  onDownloadLinkClick(e, downloadTaskCSV = false) {
    e.preventDefault();

    const { currentUser } = Rails.helpers;
    let { query } = this.state;
    query = { ...query, task_csv: downloadTaskCSV };

    const request = APIRequest.get({
      resource: '/v1/leads/export_csv',
      data:     query,
    });

    request.end((_error, response) => {
      if (response && response.ok) {
        GlobalContainer.notify(`Thanks for your request. We will generate CSV file for you and send you to email "${currentUser.email}".`);
      } else {
        GlobalContainer.notify('Oops! Something went wrong!', 'error');
      }
    });
  }

  /**
   * Extract and return table stored data
   * @param {Object} table - table config data
   * @return {{loading, records, query, ownerScope, leadScopeSize}}
   */
  getScopedTableData(table) {
    const {
      scopedLoadings,
      scopedRecords,
      scopedQueries,
      ownerScope,
      leadScopeSize,
    } = AgentStore.getState();

    const {
      documentScopeSize,
    } = DocumentStore.getState();

    return {
      loading: scopedLoadings[table.scope],
      records: scopedRecords[table.scope],
      query:   scopedQueries[table.scope],
      ownerScope,
      leadScopeSize,
      documentScopeSize,
    };
  }

  getScopedDocumentTableData(table) {
    const documentStoreState = DocumentStore.getState();
    const {
      scopedLoadings,
      scopedRecords,
      scopedQueries,
      ownerScope,
      documentScopeSize,
      lastDocumentStoreAction,
    } = documentStoreState;

    const actionsToIgnore = [
      'loadCategoriesDone', 'loadStatusesDone', 'loadDocument',
      'loadDocumentDone', 'updateDocument', 'updateDocumentDone',
      'loadDocumentsWithRelatedContact', 'loadDocumentsWithRelatedContactDone',
    ];
    if (actionsToIgnore.includes(lastDocumentStoreAction)) {
      return null;
    }

    return {
      loading: scopedLoadings[table.scope],
      records: scopedRecords[table.scope],
      query:   scopedQueries[table.scope],
      ownerScope,
      documentScopeSize,
    };
  }

  renderScope(scope) {
    switch (scope) {
      case 'all':
        return 'All';
      case 'mine':
        return 'My';
      default:
        return _lodash.titleize(scope);
    }
  }

  renderLeadsTable() {
    const { table } = this.props;
    const {
      loading, records, query, ownerScope, leadScopeSize, documentScopeSize,
    } = this.state;

    let scopeSize;
    let tableSortField;
    let tableSortDirection;

    switch (table.scope) {
      case 'documents':
        tableSortField = { column: 'uploaded_at', title: 'Created' };
        scopeSize = documentScopeSize;
        tableSortDirection = 'desc';
        break;
      case 'trash_documents':
        tableSortField = { column: 'deleted_at', title: 'Deleted At' };
        scopeSize = documentScopeSize;
        tableSortDirection = 'desc';
        break;
      default:
        tableSortField = { column: 'id', title:  'ID' };
        scopeSize = leadScopeSize;
        tableSortDirection = null;
    }

    return (
      <DataTableContent
        table={table}
        query={query}
        clickable
        loading={loading}
        records={records}
        ownerScope={ownerScope}
        leadScopeSize={scopeSize}
        tableSortField={tableSortField}
        tableSortDirection={tableSortDirection}
      />
    );
  }

  renderCollectionTitle() {
    const { table } = this.props;
    const { loading, records } = this.state;

    let subtitleClass = 'ml10 text-grey';
    if (table.scope === 'trash_documents') {
      subtitleClass = 'ml10 text-grey d-block text-center';
    }

    let cardTitle = (
      <h4 className="mb10">
        {table.title}
        {table.subtitle ? (
          <small className={subtitleClass}>
            {table.subtitle}
            {table.scope === 'trash_documents' && (
            <a href="/empty-trash" onClick={this.handleEmptyTrash} className="ml10 text-green">Empty Trash Now</a>
            )}
          </small>
        ) : null}
      </h4>
    );

    if (records && loading === false && location.pathname === '/recruiter/search') {
      cardTitle = <h4 className="mb10">{`${table.title} (${records.pagination.total_count})`}</h4>;
    }

    if (loading && location.pathname === '/recruiter/search') {
      cardTitle = <h4 className="mb10">Searching...</h4>;
    }

    return cardTitle;
  }

  renderReferrerAction() {
    const { table } = this.props;
    const { currentUser, currentTeam } = Rails.helpers;
    const { helpers } = this.context;

    if (currentUser.staff_type !== 'external' || table.scope !== 'agent_referrals') return null;

    return (
      <div className="alert alert-success mt15 mb30">
        <span>Who else should we be talking to?</span>
        <button type="button" className="btn btn-primary ml20" onClick={() => helpers.openNewReferralDrawer()}>
          <FontAwesomeIcon icon={['far', 'fa-plus']} className="mr5" />
          Refer a Candidate
        </button>
        {
          currentTeam.landing_page
          && (
          <a
            href={`${currentTeam.landing_page.url}/${currentUser.id}`}
            target="_blank"
            className="ml10 btn btn-secondary"
            rel="noopener noreferrer"
          >
            <FontAwesomeIcon icon={['far', 'fa-share']} className="mr5" />
            {' '}
            Share Landing Page
          </a>
          )
        }
      </div>
    );
  }

  renderReferrerStatistic() {
    const { referralStats } = this.state;
    const { table } = this.props;

    if (Rails.helpers.currentUser.staff_type !== 'external' || table.scope !== 'agent_referrals' || !referralStats) return null;

    return (
      <div className="text-center mb15">
        You&#39;ve made
        <strong>
          {' '}
          {referralStats.leads_count}
          {' '}
          {_lodash.pluralize('referral', referralStats.leads_count)}
          {' '}
        </strong>
        resulting in
        <strong>
          {' '}
          {referralStats.joins_count}
          {' '}
          {_lodash.pluralize('agent', referralStats.joins_count)}
          {' '}
          joining
          {' '}
        </strong>
        with a total
        {' '}
        <strong>
          last 12 months production of
          {' '}
          {numeral(referralStats.total_value).format('$0,0[.]00')}
        </strong>
        .
      </div>
    );
  }

  render() {
    const { loading } = this.state;
    const { params, table } = this.props;
    const { downloadTaskCSV } = params;

    return (
      <div className="card">
        <div className="card-block">
          {!loading && Rails.abilities.manageLead
          && table.scope !== 'trash_documents' && table.scope !== 'documents' && (
            <div className="pull-right">
              <a href="#download-csv" onClick={(e) => this.onDownloadLinkClick(e, downloadTaskCSV)} className="btn btn-secondary btn-sm">
                <FontAwesomeIcon icon={['fas', 'fa-download']} className="mr5" />
                {' '}
                Download CSV
              </a>
            </div>
          )}
          {this.renderCollectionTitle()}
          {this.renderReferrerAction()}
          {this.renderReferrerStatistic()}
        </div>

        {this.renderLeadsTable()}
      </div>
    );
  }
}

DataTable.contextTypes = {
  helpers: PropTypes.shape({}),
};

DataTable.defaultProps = {
  table:  {},
  params: {
    loadReferrerStats: false,
  },
};

DataTable.propTypes = {
  table:  PropTypes.shape({}),
  params: PropTypes.shape({}),
};

export default DataTable;
