import React, {
  forwardRef, useImperativeHandle, useRef, useEffect, useState, useCallback, useReducer,
} from 'react';
import { VariableSizeList as List } from 'react-window';
import AutoSizer from 'react-virtualized-auto-sizer';
import InfiniteLoader from 'react-window-infinite-loader';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useLocation, useHistory } from 'react-router-dom';
import useSmoothScrollToElement from '~/components/hooks/useSmoothScrollToElement';
import Item from './Item';

const OVERSCAN_COUNT = 20;

const InfiniteScroll = forwardRef(({
  items,
  renderItem,
  itemSize,
  nextPage,
  prevPage,
  isNextPageLoading,
  setLoadingActivities,
  loadNextPage,
  activeTab,
  setActiveTab,
  lead,
  isFormOpen,
  editFormState,
  isActivitiesComponent,
}, ref) => {
  const listRef = useRef();
  const [, forceUpdate] = useState();
  const rowHeights = useRef({});
  const location = useLocation();
  const history = useHistory();
  const itemCount = nextPage || prevPage ? items.length + 1 : items.length;
  const [targetActivityId, setTargetActivityId] = useState(null);
  const [isEndReached, setIsEndReached] = useState(false);

  useEffect(() => {
    if (listRef.current) {
      listRef.current?.resetAfterIndex(0);
    }
  }, [isFormOpen]);

  const loadMoreItems = useCallback((startIndex, stopIndex) => {
    if (!isNextPageLoading && !isEndReached) {
      if (startIndex === 0 && prevPage) {
        loadNextPage('prev');
      } else if (stopIndex >= items.length - 1 && nextPage) {
        loadNextPage('next');
      } else if (stopIndex >= items.length - 1 && !nextPage) {
        setIsEndReached(true);
      }
    }
  }, [isNextPageLoading, prevPage, nextPage, loadNextPage, items.length, isEndReached]);

  const handleItemsRendered = useCallback(({ onItemsRendered }) => ({
    visibleStartIndex,
    visibleStopIndex,
    overscanStartIndex,
    overscanStopIndex,
  }) => {
    onItemsRendered({
      visibleStartIndex,
      visibleStopIndex,
      overscanStartIndex,
      overscanStopIndex,
    });

    if (visibleStartIndex === 0 && prevPage && !isNextPageLoading) {
      loadMoreItems(visibleStartIndex, visibleStopIndex);
    } else if (visibleStopIndex >= items.length - 5 && nextPage && !isNextPageLoading && !isEndReached) {
      loadMoreItems(visibleStartIndex, visibleStopIndex);
    }
  }, [items.length, prevPage, nextPage, isNextPageLoading, loadMoreItems, isEndReached]);

  useEffect(() => {
    if (!isNextPageLoading && listRef.current) {
      listRef.current?.resetAfterIndex(0);
      setIsEndReached(false);
    }
  }, [isNextPageLoading]);

  const isItemLoaded = (index) => {
    const result = index < items.length;
    return result;
  };

  const setRowHeight = useCallback((index, size) => {
    if (rowHeights.current[index] !== size) {
      rowHeights.current[index] = size;
      if (listRef.current) {
        window.requestAnimationFrame(() => {
          listRef.current?.resetAfterIndex(index);
        });
      }
    }
  }, []);

  const getRowHeight = (index) => {
    const height = rowHeights.current[index] ?? itemSize;
    return height;
  };

  useImperativeHandle(ref, () => ({
    scrollToItem: (index, align = 'auto') => {
      listRef.current?.scrollToItem(index, align);
    },
    forceUpdateList: () => {
      forceUpdate({});
      if (listRef.current) {
        listRef.current?.resetAfterIndex(0, true);
      }
    },
  }));

  useEffect(() => {
    if (typeof setLoadingActivities !== 'function') return;

    setTimeout(() => {
      listRef.current?.scrollToItem(0, 'start');
    }, 100);

    if (isNextPageLoading) {
      setLoadingActivities(false);
    }
  }, [activeTab]);

  useEffect(() => {
    if (location.hash) {
      const hash = location.hash.replace('#', '');
      setTargetActivityId(hash);
    }
  }, [location.hash]);

  useEffect(() => {
    if (listRef.current) {
      listRef.current?.resetAfterIndex(0, true);
    }
  }, [items]);

  useEffect(() => {
    const { pathname, hash, search } = location;
    const normalizedHash = hash.replace('#', '');
    const activitiesPaths = ['/recruiter/today', '/retention/today'];

    const isOnActivitiesPage = activitiesPaths.some((path) => pathname.includes(path));

    const isPageRefresh = performance?.getEntriesByType
      ? performance.getEntriesByType('navigation')[0].type === 'reload'
      : false;

    if (isPageRefresh && normalizedHash && isOnActivitiesPage) {
      history.replace(`${pathname}${search}`);
      setActiveTab('all');
      loadNextPage('init');
    }
  }, [location, history]);

  const renderLoading = () => (
    <div
      style={{
        marginLeft: '390px',
        marginTop:  '20px',
      }}
    >
      <FontAwesomeIcon icon="fa-spinner" pulse />
    </div>
  );

  useSmoothScrollToElement(location, setTargetActivityId, targetActivityId, items, loadMoreItems);

  return (
    <AutoSizer>
      {({ height, width }) => (
        <>
          <InfiniteLoader
            isItemLoaded={isItemLoaded}
            itemCount={itemCount}
            loadMoreItems={loadMoreItems}
          >
            {({ onItemsRendered, ref: infiniteLoaderRef }) => (
              <>
                <List
                  ref={(list) => {
                    infiniteLoaderRef(list);
                    listRef.current = list;
                  }}
                  className="List"
                  height={height}
                  width={width}
                  itemCount={itemCount}
                  itemSize={getRowHeight}
                  overscanCount={OVERSCAN_COUNT}
                  onItemsRendered={handleItemsRendered({ onItemsRendered })}
                  itemData={{
                    items,
                    renderItem,
                    setRowHeight,
                    itemSize,
                    isItemLoaded,
                    activeTab,
                    lead,
                    listRef,
                    isFormOpen,
                    editFormState,
                    forceUpdateList: () => ref.current?.forceUpdateList(),
                  }}
                >
                  {Item}
                </List>
              </>
            )}
          </InfiniteLoader>
          <div className="w-100">
            { isActivitiesComponent && isNextPageLoading && renderLoading() }
          </div>
        </>
      )}
    </AutoSizer>
  );
});

export default InfiniteScroll;
